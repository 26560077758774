import React from "react";

const Service = ({ img, title }) => {
  return (
    <div className="cursor-pointer hover:scale-105 transform transition duration-300 ease-out">
      <div className="relative h-60 w-60 md:h-70 md:w-70 lg:h-80 md:w-80 ">
        <img
          src={img}
          layout="fill"
          objectFit="contain"
          alt="services Cards"
          className="rounded-xl h-60 w-60 md:h-70 md:w-70 lg:h-80 md:w-80"
        />
      </div>
      <h3 className="text-2xl mt-3 text-[15px] md:text-[20px]">{title}</h3>
    </div>
  );
};

export default Service;
