import "./App.css";
import Body from "./components/Body/Body";
import Footer from "./components/footer/Footer";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import NavBar from "./components/Header/NavBar";
import About from "./pages/about/about";
import Contact from "./pages/contact/contact";
import Services from "./components/services/Services";
import Places from "./components/places/Places";
import "@themesberg/flowbite";

function App() {
  return (
    <BrowserRouter>
      <div className="App">
        <Routes>
          <Route
            path="/"
            element={
              <>
                <NavBar />
                <Body />
                <Services />
                <Footer />
              </>
            }
          ></Route>
          <Route path="/About" element={<About />}></Route>
          <Route path="/Contact" element={<Contact />}></Route>
          <Route path="/services" element={<Services />}></Route>
          <Route
            path="/places"
            element={
              <>
                <NavBar />
                <Places />
                <Footer />
              </>
            }
          ></Route>
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
