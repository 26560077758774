// /lib/sanity.js
import sanityClient from "@sanity/client";

// See the image above on how to get your projectId and add a new API token
// I added one called "landing page"
const client = sanityClient({
  projectId: "svuv7y2a",
  dataset: "production",
  apiVersion: "2021-08-31",
  

  token:
    "skNEdNJfcQGwLFNahk6KepLVPG0pEhDhcfJfEmvtXZXhbtSIcDlfEFodLhAIvC1iuCx0YBjpoqXhhIlBjcYblNk3q3KMb95R3DRCqPJYEjyccgk1ZYjOAqzFcvuayDUxyzK7iPtVyiLSXVFR1Y6hCCM5m5g40YDReumzpw8T4tljKJSctSkc",
  useCdn: false,
  ignoreBrowserTokenWarning: true,
});

export default client;
