import React from "react";
// import "flowbite";
// import place from "../../img/place.jpg";
import { LocationMarkerIcon } from "@heroicons/react/solid";
import { useState } from "react";
import "@themesberg/flowbite";
import client from "../../lib/sanity";
import imageUrlBuilder from "@sanity/image-url";

const Place = ({
  troupeName,
  region,
  localite,
  description,
  img,
  placeShow,
  discipline,
  dateBorned,
  managerName,
  personNumber,
  phoneref,
  whatsappNumber,
  email,
  urlTroupe,
  event,
}) => {
  const [showModal, setShowModal] = useState(false);
  const builder = imageUrlBuilder(client);

  function urlFor(source) {
    return builder.image(source);
  }
  return (
    <div class="max-w-sm bg-white rounded-lg border border-gray-200 shadow-md dark:bg-gray-800 dark:border-gray-700 m-5 ">
      <img class="rounded-t-lg w-full h-60" src={urlFor(img).url()} alt="" />
      <div class="p-5">
        <span>
          <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
            {troupeName}
          </h5>
        </span>
        <p class="mb-3 font-normal text-gray-700 dark:text-gray-400">
          {description}
        </p>
        <p class="mb-3 font-normal text-gray-700 dark:text-gray-400">{event}</p>
        <div className="flex space-x-5">
          <>
            <>
              <button
                className="block text-white bg-arts-booster-color hover:bg-arts-color font-bold  hover:text-black focus:ring-blue-300 flex font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                type="button"
                onClick={() => setShowModal(true)}
              >
                <span>Voir plus</span>
                <svg
                  class="ml-2 -mr-1 w-4 h-4"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
              </button>

              {showModal ? (
                <>
                  <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                    <div className="relative w-auto my-6 mx-auto max-w-3xl">
                      {/*content*/}
                      <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                        {/*header*/}
                        <div className="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
                          <h3 className="text-3xl font-semibold">
                            Détails :{" "}
                            <span className="text-xl font-semibold">
                              {troupeName}
                            </span>
                          </h3>
                          <button
                            className="p-2 px-[1rem] rounded-md ml-auto bg-arts-color border-1 text-black float-right text-xl leading-none font-semibold "
                            onClick={() => setShowModal(false)}
                          >
                            <span>X</span>
                          </button>
                        </div>
                        {/*body*/}
                        <div className="relative p-6 flex-auto">
                          <p className="my-4 text-blueGray-500 text-lg leading-relaxed w-[350px]">
                            {description}
                          </p>
                          <p>Region : {region}</p>
                          <p>Localité : {localite}</p>
                          <p>Lieux de Spectacle : {placeShow}</p>
                          <p>Discipline Artistique : {discipline}</p>
                          <p>Date de Création : {dateBorned}</p>
                          <p>Nom du Premier responsable: {managerName}</p>
                          <p>Nombre de Person: {personNumber}</p>
                          <p>Numéros de téléphone de références: {phoneref}</p>
                          <p>Numéro whatsapp: {whatsappNumber}</p>
                          <p>Email: {email}</p>

                          <button
                            type="button"
                            className="text-black  hover:shadow-md hover:bg-arts-booster-color hover:text-white font-bold items-center rounded-lg  text-sm px-5 py-2.5 text-center flex bg-arts-color space-x-3 mr-3 md:mr-0 dark:bg-arts-booster-color dark:hover: dark:focus:ring-arts-booster-color"
                          >
                            <a href={urlTroupe} alt="Troupe url">
                              Visuelle Cartographique de {troupeName}
                            </a>
                            <p>
                              <LocationMarkerIcon className="h-6 w-6  text-arts-booster-color hover:text-arts-color" />
                            </p>
                          </button>
                        </div>
                        {/*footer*/}
                        <div class="flex items-center p-6 space-x-2 rounded-b border-t border-gray-200 dark:border-gray-600">
                          <button
                            type="button"
                            className="text-black  hover:shadow-md hover:bg-arts-booster-color hover:text-white font-bold items-center rounded-lg  text-sm px-5 py-2.5 text-center flex bg-arts-color space-x-3 mr-3 md:mr-0 dark:bg-arts-booster-color dark:hover: dark:focus:ring-arts-booster-color"
                          >
                            <a href="https://www.google.com/maps/d/u/0/viewer?mid=1PilX6XaE1vI5TIGM2amGx9hEBj28ojQj&ll=11.601209420886072%2C-1.3068389999999974&z=9">
                              Map
                            </a>
                            <p>
                              <LocationMarkerIcon className="h-6 w-6  text-arts-booster-color hover:text-arts-color" />
                            </p>
                          </button>

                          <button
                            data-modal-toggle="defaultModal"
                            type="button"
                            class="text-black font-bold bg-arts-color hover:bg-arts-booster-color hover:text-white focus:ring-4 focus:ring-gray-300 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600"
                            onClick={() => setShowModal(false)}
                          >
                            Annuler
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                </>
              ) : null}
            </>
          </>
        </div>
      </div>
    </div>
  );
};

export default Place;
