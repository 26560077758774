import React from "react";
import logo from "../../img/artsbooster.png";
import { LocationMarkerIcon } from "@heroicons/react/solid";
import "@themesberg/flowbite";
import { Link } from "react-router-dom";
// import {Footer} from "../footer/Footer"

function NavBar() {
  return (
    <nav className="sticky top-0 z-50 bg-arts-booster-color border-gray-200 px-2 sm:px-4 py-2 rounded dark:bg-gray-800">
      <div className="container flex flex-wrap justify-between items-center mx-auto">
        {/* <a href="#logo" className="flex">
        </a> */}
        <Link to="/">
          <img src={logo} alt="" className=" h-20 w-20 object-contain" />
        </Link>
        <div className="flex md:order-2">
          <button
            type="button"
            className="text-black  animate-bounce hover:shadow-md  font-bold items-center rounded-3xl text-sm px-5 py-2.5 text-center flex bg-arts-color space-x-3 mr-3 md:mr-0 dark:bg-arts-booster-color dark:hover: dark:focus:ring-arts-booster-color"
          >
            <a href="https://www.google.com/maps/d/u/0/viewer?mid=1PilX6XaE1vI5TIGM2amGx9hEBj28ojQj&ll=11.601209420886072%2C-1.3068389999999974&z=9">
              Map
            </a>
            <p>
              <LocationMarkerIcon className="h-6 w-6  text-arts-booster-color " />
            </p>
          </button>
          <button
            data-collapse-toggle="mobile-menu-4"
            type="button"
            className="inline-flex items-center p-2 text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
            aria-controls="mobile-menu-4"
            aria-expanded="false"
          >
            <span className="sr-only">Open main menu</span>
            <svg
              className="w-6 h-6"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                clip-rule="evenodd"
              ></path>
            </svg>
            <svg
              className="hidden w-6 h-6"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clip-rule="evenodd"
              ></path>
            </svg>
          </button>
        </div>
        <div
          className="hidden justify-between items-center w-full md:flex md:w-auto md:order-1"
          id="mobile-menu-4"
        >
          <ul className="flex flex-col mt-4 md:flex-row md:space-x-8 md:mt-0 md:text-sm md:font-medium  px-20 ">
            <li>
              <Link to="/">
                <p
                  className="block py-2 pr-4 pl-3 text-gray-700  rounded md:bg-transparent md:text-black md:p-0 dark:text-white md:dark:hover:text-white font-semibold md:hover:text-arts-color dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white
                md:dark:hover:text-white dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white mr-10"
                  aria-current="page"
                >
                  Home
                </p>
              </Link>
            </li>
            <li>
              <a
                href="#footer"
                // id="#footer"
                className="block py-2 pr-4 pl-3 text-gray-700 border-b border-gray-100 hover:bg-gray-50 md:hover:bg-transparent ml-5 md:border-0 md:hover:text-arts-color font-bold md:p-0 md:dark:hover:text-white dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700"
              >
                About
              </a>
            </li>

            <li>
              <a
                href="#footer"
                // id="#footer"
                className="block py-2 pr-4 pl-3 text-gray-700 border-b border-gray-100 hover:bg-gray-50 ml-10 md:hover:bg-transparent md:border-0 md:hover:text-arts-color font-bold md:p-0 md:dark:hover:text-white dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700"
              >
                Contact
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default NavBar;
