import React from "react";
import Service from "../Service/Service";
import data from "../../Oeuvres";

const Services = () => {
  console.log(data);

  console.log("helloooooooooooooo", data);

  return (
    <section className="max-w-7xl mx-auto px-8 sm:px-16">
      <h2 className="sm:text-md md:text-2xl lg:text-4xl font-semibold py-10 ">
        Nos Oeuvres
      </h2>
      <div className="flex space-x-3 overflow-x-scroll  scrollbar-hide p-3 -ml-3">
        {data.map((item) => (
          <Service key={item.key} img={item.image} title={item.title} />
        ))}
      </div>
    </section>
  );
};

export default Services;
