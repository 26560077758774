import React from "react";

const contact = () => {
	return (
		<div>
			<h1>I'm the contact page</h1>
		</div>
	);
};

export default contact;
