import React, { useState } from "react";
import banner from "../../img/pexels-ila-bappa-ibrahim-10024516.jpg";
// import { LocationMarkerIcon } from "@heroicons/react/solid";
// import logo from "../../img/1.jpeg";
import Fade from "react-reveal/Fade";
import { Link } from "react-router-dom";
import { Carousel } from "react-responsive-carousel";
import place from "../../img/place.jpg";
import telechargement from "../../img/pix.jpg";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import camera from "../../img/camera.jpg";
import femme from "../../img/femme.jpg";
import banner1 from "../../img/Troupe Kora de Tiébélé_pour site web.jpg";

const Body = () => {
  const [color, setColor] = useState(true);
  return (
    <div className="relative h-[300px] sm:h-[400px] lg:h-[500px] xl:h-[600px] 2xl:h-[700px]">
      {/* <Carousel showThumbs={false} showStatus={false}>
        <div>
          <img
            src={banner}
            alt="banner"
            className="object-cover w-full h-[300px] sm:h-[400px] lg:h-[500px] xl:h-[600px] 2xl:h-[700px]"
          />
        </div>
        <div>
          <img
            src={place}
            alt="banner"
            className="object-cover w-full h-[300px] sm:h-[400px] lg:h-[500px] xl:h-[600px] 2xl:h-[700px]"
          />
        </div>
        <div>
          <img
            src={telechargement}
            alt="banner"
            className="object-cover w-full h-[300px] sm:h-[400px] lg:h-[500px] xl:h-[600px] 2xl:h-[700px]"
          />
        </div>
        <div>
          <img
            src={camera}
            alt="banner"
            className="object-cover w-full h-[300px] sm:h-[400px] lg:h-[500px] xl:h-[600px] 2xl:h-[700px]"
          />
        </div>
        <div className="bg-red-400">
          <img
            src={femme}
            alt="banner"
            className="object-contain w-full h-[300px] sm:h-[400px] lg:h-[500px] xl:h-[600px] 2xl:h-[700px]"
          />
        </div>
      </Carousel> */}
      <img
        src={banner1}
        alt="banner"
        className="object-cover w-full h-[300px] sm:h-[400px] lg:h-[500px] xl:h-[600px] 2xl:h-[700px]"
      />
      <Fade left>
        <div className="absolute top-[50%] w-full left-[5%] space-y-10 items-center">
          <p
            className={
              color
                ? "text-md font-semibold  lg:text-4xl md:text-lg text-white"
                : "text-md font-semibold  lg:text-4xl md:text-lg text-white"
            }
          >
            Art au coeur du developpement.
          </p>
          <Link to="/places">
            <button className="hover:animate-bounce p-3 bg-arts-color my-3 rounded-full shadow-md font-bold hover:shadow-xl active:scale-90 transition duration-150 flex space-x-2">
              {/* <a href="https://www.google.com/maps/d/u/0/viewer?mid=1PilX6XaE1vI5TIGM2amGx9hEBj28ojQj&ll=11.601209420886072%2C-1.3068389999999974&z=9">
            </a> */}
              Retrouvez Nous
              {/* <LocationMarkerIcon className="h-6 w-6 m-none text-arts-booster-color " /> */}
            </button>
          </Link>
        </div>
      </Fade>
    </div>
  );
};
document.querySelector(".demo-carousel");

export default Body;
