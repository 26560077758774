import first from "./img/1.jpeg";
import two from "./img/2.jpeg";
import three from "./img/3.jpeg";
import four from "./img/4.jpeg";
import five from "./img/5.jpeg";
import six from "./img/6.jpeg";
import seven from "./img/7.jpeg";
import eight from "./img/8.jpeg";
import nine from "./img/9.jpeg";
import ten from "./img/10.jpeg";
import dow1 from "./img/dow1.jpeg";
import dow2 from "./img/dow2.jpeg";
import dow3 from "./img/dow3.jpeg";
import dow4 from "./img/dow4.jpeg";
import dow6 from "./img/dow6.jpeg";
import dow7 from "./img/dow7.jpeg";

const data = [
  {
    key: 1,
    image: first,
    title: "Troupe Kora De Tiéblé",
  },
  {
    key: 2,
    image: two,
    title: "Troupe Sagl-Taaba de Manga",
  },
  {
    key: 3,
    image: three,
    title: "",
  },
  {
    key: 4,
    image: four,
    title: "Troupe Sagl-Taaba de Manga",
  },
  {
    key: 5,
    image: five,
    title: "",
  },
  {
    key: 6,
    image: six,
    title: "Troupe Sagl-Taaba de Manga",
  },
  {
    key: 7,
    image: seven,
    title: "Troupe Kora De Tiéblé",
  },
  {
    key: 8,
    image: eight,
    title: "",
  },
  {
    key: 9,
    image: nine,
    title: "Troupe Kora De Tiéblé",
  },
  {
    key: 10,
    image: ten,
    title: "Troupe Sagl-Taaba de Manga",
  },
  {
    key: 11,
    image: dow1,
    title: "Troupe Kora De Tiéblé",
  },
  {
    key: 12,
    image: dow2,
    title: "Troupe Kora De Tiéblé",
  },
  {
    key: 13,
    image: dow3,
    title: "TTroupe Sagl-Taaba de Manga",
  },
  {
    key: 14,
    image: dow4,
    title: "Troupe Kora De Tiéblé",
  },
  {
    key: 16,
    image: dow6,
    title: "Troupe Kora De Tiéblé",
  },
  {
    key: 17,
    image: dow7,
    title: "Troupe Sagl-Taaba de Manga",
  },
];

export default data;
