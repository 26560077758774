import React, { useEffect } from "react";
import Place from "../place/Place";
import { useState } from "react";
// import { client } from "../../lib/sanity";

// import uuid from 'uuid'

const Places = () => {
  const [troupeData, setTroupeData] = useState([]);
  useEffect(() => {
    // declare the data fetching function
    const fetchData = async () => {
      const data = await fetch(
        "https://svuv7y2a.api.sanity.io/vX/data/query/production?query=*%5B_type%3D%3D'troupe'%5D%7B%0A%20%20troupeNom%20%2C%20region%20%2C%20localite%20%2C%20showsSpace%20%2C%20disciplineArtistique%20%2C%20bornedDate%20%2C%20leadName%20%2C%20description%20%2Cevent%20%2C%20%20numberPerson%20%2C%20email_me%20%2C%20tele%20%2C%20teleph%20%2C%20urlTroupe%20%2C%20tel%20%2C%20image%0A%7D"
      );
      const dataa = await data.json();
      console.log("hellooooooooooooooo0", dataa.result);
      let resultat = dataa.result;
      setTroupeData(resultat);
    };

    // call the function
    fetchData()
      // make sure to catch any error
      .catch(console.error);
  }, []);
  return (
    <div className="max-w-7xl mx-auto pt-6 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
      {troupeData?.map((item , index) => (
        <Place
          key={index}
          troupeName={item.troupeNom}
          region={item.region}
          localite={item.localite}
          description={item.description}
          img={item.image}
          event={item.event}
          placeShow={item.showsSpace}
          discipline={item.disciplineArtistique}
          dateBorned={item.bornedDate}
          managerName={item.leadName}
          personNumber={item.numberPerson}
          phoneref={item.tel}
          whatsappNumber={item.teleph}
          email={item.email_me}
          urlTroupe={item.urlTroupe}
        />
      ))}
    </div>
  );
};

export default Places;
