import React from "react";
import logo from "../../img/artsbooster.png";
import gelyz from "../../img/gelyz.png";
import wakatlab from "../../img/wakatlab.jpeg";
import union from "../../img/union.jpeg";
import minister from "../../img/minister.jpeg";
import { MailIcon } from "@heroicons/react/solid";
import { AiFillFacebook } from "react-icons/ai";
import { AiFillYoutube } from "react-icons/ai";
import { Link } from "react-router-dom";
import NavBar from "../Header/NavBar"

const Footer = () => {
  return (
    <div
      className="w-full px-32 py-20  text-gray-600 bg-arts-booster-color shadow-md"
      id="footer"
    >
      <div className="grid grid-cols-1 md:grid-cols-4 gap-y-10">
        <div className="space-y-4 text-sm font-medium text-white cursor-pointer">
          <Link to="/">
            <img
              src={logo}
              alt="mon masque"
              className="h-20 w-20  cursor-pointer object-contain"
            />
          </Link>
          <p className="text-sm w-3/4 md:text-md">
            Créé en Décembre 2022, Arts-boosterbf est un espace d’innovation et
            de conception dont le siège est dans le quartier Kalgodin de
            Ouagadougou.
          </p>
        </div>
        <div className="space-y-4  text-white text-sm font-medium cursor-pointer">
          <h5 className="font-bold text-gray-800 ">FONDATEUR</h5>
          <p className="font-semibold">Mise en Place par :</p>
          <span className="flex space-x-5">
            <img
              src={gelyz}
              alt="mon masque"
              className="h-20 w-20  cursor-pointer object-contain"
            />
            <img
              src={wakatlab}
              alt="mon masque"
              className="h-20 w-20  cursor-pointer object-contain"
            />
          </span>
        </div>
        <div className="space-y-4  text-white text-sm font-medium cursor-pointer">
          <h5 className="font-bold text-gray-800 ">PARTENAIRES</h5>
          <p className="font-semibold">Financé par : </p>
          <span className="flex space-x-5">
            <img
              src={minister}
              alt="mon masque"
              className="h-20 w-20  cursor-pointer object-contain"
            />
            <img
              src={union}
              alt="mon masque"
              className="h-20 w-20  cursor-pointer object-contain"
            />
          </span>
        </div>
        <div className="space-y-4  text-white text-sm font-medium">
          <h5 className="font-bold text-gray-800 ">NOS CONTACTS</h5>
          <p>
            <span className="font-bold">Adresse:</span> Kalgondin, Ouagadougou
          </p>
          <p>
            <span className="font-bold">Téléphone:</span> 00226 70557095 |
            00226 76633902
          </p>
          <p>
            <span className="font-bold">E-mail:</span> gelyzvalor@gmail.com
          </p>
          <p className="flex space-x-4 mt-10">
            <span>
              <a href="mailto:gelyzvalor@gmail.com">
                <MailIcon className="text-red-700 h-10 w-10" />
              </a>
            </span>

            <span>
              <a href="https://facebook.com/wakatlab">
                <AiFillFacebook className="text-blue-700 h-10 w-10" />
              </a>
            </span>
            <span>
              <a href="https://www.youtube.com/channel/UC8-u7BDiQkKuvqGCbojppFQ">
                <AiFillYoutube className="text-red-700 h-10 w-10" />
              </a>
            </span>
          </p>
        </div>
      </div>
      <div className="text-center mt-3 text-center item-center text-white">
        © 2022 Copyright:
        <a href="#artsbooter"> ArtsBoooster</a>
      </div>
    </div>
  );
};

export default Footer;
